
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { PlantStoreMixin } from "@/mixins/Stores/PlantStoreMixin";
import GrowTent from "@/components/svg/GrowTent.vue";

@Component({
  components: { GrowTent },
})
export default class Default extends mixins(PlantStoreMixin) {}
